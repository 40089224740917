<template>
  <div id="pieDiv" ref="pieDiv"></div>
</template>

<script setup>
import * as echarts from "echarts";
import { defineProps, watch, reactive,defineEmits } from "vue";
const props = defineProps({
  seriesData: {
    type: Array,
    required: true,
  },
});
const textData = reactive([]);
let myChart
const drawPie = (seriseData) => {
  var chartDom = document.getElementById("pieDiv");
  myChart =  echarts.init(chartDom);
  const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
  let options = {
    tooltip: {
      trigger: "item",
      formatter: (params) => {
        let str = `<p>${params.name}</p>`;
        str += `<p>占比：${params.value.toFixed(2)}%</p>`;
        // str += `<p>TGI：${params.data.tgi.toFixed(2)}</p>`;
        return str;
      },
    },
    legend: {
      data:seriseData,
      orient:'vertical',
      top: "center",
      right: "20px",
      itemAlign:'right',
      formatter:(params) =>{
        return params + ' ' + seriseData.find(item => item.name === params).value.toFixed(1) + '%'
      }
    },
    title: {
      text: "产品维度下观点数分布",
      textStyle: {
        fontWeight: "normal",
        fontSize: 14,
      },
      left: 5,
      top: 0,
    },
    series: [
      {
        type: "pie",
        radius: ['25%', "50%"],
        avoidLabelOverlap: false,
        silent:true,
        legendHoverLink:false,
        label: {
          normal: {
            formatter: "",
          },
        },
        labelLine: {
          show: false,
        },
        center: ["25%", "50%"],
        data: seriseData,
        emphasis:{
          itemStyle:{
            color:"inherit",
          }
        }
      },
    ],
    color: ["#52A6F0", "#F8CBAD","#FBE5D6","#C5E0B4","#FFE699","#A9CAE9"],
  };
  myChart.on('legendselectchanged', function (params) {
    myChart.setOption({
      legend: {
        selected: {
          [params.name]: true
        }
      }
    })
    seriseData.forEach((item,index) => {
      if (item.name !== params.name) {
        myChart.dispatchAction({
          type: 'downplay',//取消高亮显示;
          seriesIndex: 0,
          dataIndex: index
        });
      }
      else{
        myChart.dispatchAction({
          type: 'highlight',//突出高亮显示;
          seriesIndex: 0,
          dataIndex: index
        });
      }
    })

    change(params.name)
  });
  options && unwarp(myChart).setOption(options);
  myChart.dispatchAction({
    type: 'highlight',//突出高亮显示;
    seriesIndex: 0,
    dataIndex: 0
  });
  if(props.seriesData.length > 6){
    let height = (props.seriesData.length - 6)* 15 + 270
    myChart.resize({height:height});
    chartDom.style.height = height + 'px'
  }
};
watch(
    () => props.seriesData,
    (val) => {
      if (val.length !== 0) {
        textData.value = val;
        drawPie(val);
      }
    },
    {
      deep: true,
    }
);
const change = (params) => {
  emit('legendselectchanged',params)
}
const emit = defineEmits(["legendselectchanged"]);
</script>

<style lang="scss">
#pieDiv {
  flex: 1;
  //width: 100%;
  height:270px;
  width: 310px;
}
</style>
